<template>
  <b-card-actions
    ref="alertTypeCard"
    title="Alert Types"
    action-refresh
    @refresh="refreshStop('alertTypeCard')"
  >
    <b-table
      responsive
      :items="items"
      :fields="fields"
      class="mb-0"
    >
      <!-- Column: Active -->
      <template #cell(active)="data">
        <div class="text-nowrap">
          <b-form-checkbox
            :checked="data.item.active"
            name="check-button"
            switch
            inline
          >
          </b-form-checkbox>
        </div>
      </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50"> Delete </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
  </b-card-actions>
</template>

<script>
import api from '@mlfcore/api'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTable, BFormCheckbox,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import tempData from '@mlfcore/api/tempdata'
// import { resolveActiveIcon, resolveActiveVariant } from '@mlfcore/helpers'

export default {
  components: {
    BCardActions,
    BTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      card: 'alertTypeCard',
      fields: [
        { key: 'id', label: '#' },
        // { key: 'error_id', label: 'ID' },
        // { key: 'code', label: 'Code' },
        // { key: 'title', label: 'Title' },
        // { key: 'active', label: 'Active' },
        // { key: 'actions', label: 'Actions' },
      ],
      items: tempData,
    }
  },
  mounted() {
    this.$refs[this.card].showLoading = true
    this.initalize()
  },
  methods: {
    initalize() {
      this.getData()
    },
    getData() {
      api.getDataverse('purchasetypes', {
        count: 10,
        page: 1,
      }).then(response => {
        /* eslint-disable no-console */
        console.log(response)
        this.items = response
      }).catch(error => {
        /* eslint-disable no-console */
        console.log(error)
      }).then(() => {
        this.$refs[this.card].showLoading = false
      })
    },
    // stop refreshing card in 3 sec
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },
  },
}
</script>
